import "../styles/globals.css";
import { Montserrat } from "next/font/google";
import type { AppProps } from "next/app";
import { IntlProvider } from "react-intl";
import { Locale } from "../shared/types";
import { flatten } from "flat";
import { useRouter } from "next/router";
import {
  getTranslationsFromLocale,
  localeFromString,
} from "../shared/i18n/localization";
import {
  getFrontendTenantConfigurationById,
  getFrontendTenantConfigurationByName,
} from "../shared/contexts/tenantsConfigurations";
import { useEffect } from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Script from "next/script";
import * as Sentry from "@sentry/react";
import { ErrorFallback } from "../components/ErrorFallback";

const montserrat = Montserrat({
  weight: ["400", "600"],
  style: ["normal", "italic"],
  subsets: ["latin"],
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { locale: localeCandidate, query, pathname, asPath } = router;

  const tenantConfiguration =
    (pageProps.tenantId
      ? getFrontendTenantConfigurationById(pageProps.tenantId)
      : typeof query.tenantSlug === "string"
        ? getFrontendTenantConfigurationByName(query.tenantSlug)
        : undefined) ?? getFrontendTenantConfigurationByName("de"); // for 404 pages

  if (!tenantConfiguration) {
    throw new Error("Tenant configuration not found"); // should never happen as 'de' is always available
  }

  const locale =
    localeFromString(localeCandidate) ?? tenantConfiguration.defaultLocale;

  useEffect(() => {
    if (!tenantConfiguration.enabledPatientLocales.includes(locale)) {
      router.push({ pathname, query }, asPath, { locale: Locale.de });
    }
  }, [tenantConfiguration, locale, router, pathname, query, asPath]);

  const theme = extendTheme(tenantConfiguration.theme, {
    fonts: {
      heading: montserrat.style.fontFamily,
      body: montserrat.style.fontFamily,
    },
  });

  return (
    <Sentry.ErrorBoundary fallback={Fallback}>
      <Script id="load-dataLayer">
        {`window.dataLayer = window.dataLayer || [];`}
      </Script>
      <IntlProvider
        locale={locale}
        messages={flatten(getTranslationsFromLocale(locale))}
      >
        <ChakraProvider theme={theme}>
          <Component {...pageProps} />
        </ChakraProvider>
      </IntlProvider>
    </Sentry.ErrorBoundary>
  );
}

const Fallback = () => {
  return <ErrorFallback />;
};

export default MyApp;
