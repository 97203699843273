import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Locale } from "../types";
import English from "../../lang/en.json";
import French from "../../lang/fr.json";
import Italian from "../../lang/it.json";
import German from "../../lang/de.json";
import SwissGerman from "../../lang/de-ch.json";

type TranslationFunction = (translationKey: string, values?: any) => any;

type UseTranslationsReturnType = {
  translate: TranslationFunction;
  locale: Locale;
};

export const useTranslations = (): UseTranslationsReturnType => {
  const intl = useIntl();

  const translate: TranslationFunction = useMemo(
    () => (translationKey, values) =>
      intl.formatMessage({ id: translationKey }, values, { ignoreTag: true }),
    [intl]
  );

  return { translate, locale: intl.locale as Locale };
};

export const localeFromString = (value: string = "") =>
  isLocale(value) ? value : undefined;

export const isLocale = (value: string): value is Locale =>
  Object.keys(Locale).includes(value);

export const getTranslationsFromLocale = (locale: Locale) => {
  switch (locale) {
    case Locale.de:
      return German;
    case Locale["de-ch"]:
      return SwissGerman;
    case Locale.fr:
      return French;
    case Locale.it:
      return Italian;
    case Locale.en:
      return English;
    default:
      return German;
  }
};
