import * as React from "react";
import { Box, Spacer, Text } from "@chakra-ui/react";

interface ErrorFallbackProps {}

export const ErrorFallback: React.FC<ErrorFallbackProps> = () => {
  return (
    <Box padding={5}>
      <Text textStyle="p">OnlineDoctor</Text>
      <Text textStyle="h1">Something went wrong. We are very sorry.</Text>
      <Spacer h={5} />
      <Text>
        Our team has been notified of this error and we will do our best to
        resolve the issue as soon as possible.
      </Text>
      <Text>Please try again later.</Text>
      <Spacer h={5} />
      <Text>
        Should this error persist please contact{" "}
        <a href="mailto:support@onlinedoctor.ch">support@onlinedoctor.ch</a>.
      </Text>
      <Spacer h={5} />
      <Text>
        <a href="https://www.onlinedoctor.ch">www.onlinedoctor.ch</a>
      </Text>
      <Text>
        <a href="https://www.onlinedoctor.de">www.onlinedoctor.de</a>
      </Text>
    </Box>
  );
};
