export enum Locale {
  de = "de",
  en = "en",
  fr = "fr",
  it = "it",
  "de-ch" = "de-ch",
}

export interface AddressData {
  city: string;
  line1: string;
  line2?: string;
  postal_code: string;
  region?: string;
  country: string;
}

export interface DoctorDataResponse {
  data: Doctor[];
  search_after_token?: string;
  workplace_geopoint?: GeoLocation;
}

export interface Doctor {
  is_available?: boolean;
  personal_information?: {
    title: string;
    forename: string;
    surname: string;
    gender: string;
  };
  email: string;
  legal_information?: {
    austria?: {
      anr: string;
    };
    germany?: {
      lanr: string;
      is_gkv_member: boolean;
      bsnr: string;
    };
    switzerland?: {
      gln: string;
      zsr: string;
    };
  };
  payment_information?: {
    manual?: {
      iban: string;
    };
    stripe?: {
      iban: string;
      date_of_birth: string;
      phone_number: string;
      address: {
        line1: string;
        postal_code: string;
        city: string;
        region: string;
        country: string;
      };
      account_creation_intent: boolean;
      terms_accepted_ip: string;
      terms_accepted_date: string;
      account_id: string;
    };
  };
  correspondence_language?: string;
  additional_languages?: string[];
  display_settings?: {
    slug: string;
    welcome_message: string;
    external_practice_link: string;
    public_email: string;
    public_phone_number: string;
    photo_urls: {
      small: string;
      medium: string;
      large: string;
      original: string;
    };
  };
  sms_reminder_active?: boolean;
  mobile_phone_number?: string;
  id: string;
  account_id: string;
  terms_and_conditions_last_accepted: string;
  can_create_prescription?: boolean;
  is_active?: boolean;
  last_modified?: Date;
  workplaces: Workplace[];
  handles_only_private_cases?: boolean;
}

export interface PublicDoctorInformation {
  is_available?: boolean;
  personal_information?: {
    title: string;
    forename: string;
    surname: string;
    gender: string;
  };
  legal_information?: {
    austria?: {
      anr: string;
    };
    germany?: {
      lanr: string;
      is_gkv_member: boolean;
      bsnr: string;
    };
    switzerland?: {
      gln: string;
      zsr: string;
    };
  };
  correspondence_language?: string;
  additional_languages?: string[];
  display_settings?: {
    slug: string;
    welcome_message: string;
    external_practice_link: string;
    public_email: string;
    public_phone_number: string;
    photo_urls: {
      small: string;
      medium: string;
      large: string;
      original: string;
    };
  };
  id: string;
  can_create_prescription?: boolean;
  is_active?: boolean;
  last_modified?: Date;
  workplaces: Workplace[];
}

export interface Position {
  longitude?: number;
  latitude?: number;
}

// Needed to support the code that was copied over for the Doctor Search page.
export interface GeoLocation {
  longitude: number;
  latitude: number;
}

export interface WorkplaceLocation extends Position {
  google_places_id: string;
}

export interface Workplace extends WorkplaceLocation {
  address: AddressData;
  description?: string;
  id: string;
  name: string;
  phone_number?: string;
  slug: string;
  last_modified?: Date;
  doctors?: Doctor[];
}

export interface CityInfo {
  name: string;
  radius: number;
}
